<template>
  <tr>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ file.name }}</span>
    </td>
    <td>
      <span class="text-dark-75 d-block font-size-lg">{{ file.created_at }}</span>
    </td>
    <td>
      <b-form-input
          id="input-descr"
          v-model="descr"
          type="text"
          v-on:change="onDescrUpdated"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
          id="input-amount"
          v-model="amount"
          type="text"
          v-on:change="onSumUpdated"
      ></b-form-input>
    </td>


    <td class="text-right pr-0">
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="downloadFileClicked(file.file_id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a v-if="enableDelete" class="btn btn-icon btn-light btn-sm" @click="deleteFileClicked(file.file_id)">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a v-if="!enableDelete" href="#" class="btn btn-icon btn-light btn-sm" >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <!--end::Svg Icon-->
        </span>
      </a>

    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'grant-file-table-row',
  props: ['file', 'type'],
  emits: ['downloadFileClicked', 'selectFileClicked', 'deleteFileClicked', 'onDescrUpdated', 'onSumUpdated'],
  computed: {
    ...mapGetters(['currentCompanyId']),
    enableDelete: function () {
      var from = dayjs(this.file.from);
      var now = dayjs();

      return (this.type === 'live' && now < from) || this.type !== 'live';
      //return true;
    }
  },
  data() {
    return {
      amount: 0,
      descr: ''
    };
  },
  mounted() {
    this.amount = this.file.amount;
    this.descr = this.file.descr;
  },
  watch: {},
  methods: {
    downloadFileClicked(file_id) {
      this.$emit('downloadFileClicked', file_id);
    },
    selectFileClicked(file_id) {
      this.$emit('selectFileClicked', file_id);
    },
    deleteFileClicked(file_id) {
      this.$emit('deleteFileClicked', file_id);
    },
    onDescrUpdated(evt) {
      this.$emit('onDescrUpdated', this.file.file_id, this.descr);
    },
    onSumUpdated(evt) {
      this.$emit('onSumUpdated', this.file.file_id, this.amount);
    }
  }
};
</script>
