<template>
  <div>

    <div v-if="!task" class="alert alert-custom alert-danger alert-light-primary fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Bidragsåtgärder hittades ej</div>

    </div>

    <div v-else class="card card-custom card-stretch px-5">
      <!--begin::Body-->
      <div class="card-body pt-3 pb-3">
        <h5 class="mb-4 mt-4">{{ task.name }}</h5>

        <p class="mb-8">{{ task.descr }}</p>

        <GrantFileTable
          :files="files"
          :taskStatus="task.status"
          @onUploadFileClicked="selectFile"
          @downloadFileClicked="downloadFileClicked"
          @deleteFileClicked="deleteFileClicked"
          @onSumUpdated="onSumUpdated"
          @onDescrUpdated="onDescrUpdated"
        />

        <input
          hidden
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
          :accept="getExtensions()"
        />

        <b-row>
          <b-col>
            <b-form-group id="input-group-amount_sum" label="Summa" label-for="input-amount_sum">
              <b-form-input
                  id="input-amount_sum"
                  v-model="amount_sum"
                  type="text"
                  disabled
              ></b-form-input>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row class="px-8 mt-8" v-if="grant.status !== 'DONE' && grant.status !== 'CLOSE'" >
          <b-col>
            <div class="d-flex align-items-center ">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input type="checkbox" name="" v-model="done" />
                    <span></span>
                </label>
                <span class="ml-3 cursor-pointer" @click="done = !done"
                >Inlämningen är klar och redo att granskas</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="px-8 mt-8" v-if="grant.status !== 'DONE' && grant.status !== 'CLOSE'">
          <b-col>
              <b-button
              :disabled="!done"
              type="button"
              variant="primary"
              @click="saveTaskClicked"
              >{{ $t('COMMON.CONFIRM') }}</b-button
            >
          </b-col>
        </b-row>

        <ChatBox
          :comments="comments"
        />

        <b-row align-h="between" class="px-8">
          <b-col>
            <b-form-group id="input-group-text" label="Skriv meddelande" label-for="input-text">
              <b-textarea id="input-text" v-model="comment" class="mb-2"> </b-textarea>
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              type="button"
              style="margin-top: 26px; height: 55px; width: 120px;"
              variant="primary"
              @click="sendCommentClicked"
              >{{ $t('COMMON.SEND') }}</b-button
            >
          </b-col>
        </b-row>

        <b-row style="min-height: 128px;">

        </b-row>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ChatBox from '@/view/components/chatBox.vue';
import GrantFileTable from '@/view/pages/ml/grantwizard/GrantFileTable.vue';
import dayjs from 'dayjs';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grant-wizard-page',
  components: {
    ChatBox,
    GrantFileTable
  },
  mixins: [ toasts ],
  props: ['ref_todo_id'],
  emits: ['fileUploaded'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  data() {
    return {
      acceptLookups: {
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xslx, .xls',
        'png': 'image/png',
        'jpg': 'image/jpg',
        'pdf': '.pdf',
        'txt': 'text/plain',
        'doc': 'application/msword, .doc, .docx',
        'zip': '.zip'
      },

      done: false,
      todo_id: null,
      comment: '',
      todo_task_id: '',
      comments: [],
      files: [],
      status: '',
      grant: null,
      task: null,
      amount_sum: 0
    };
  },
  mounted() {
    if (this.ref_todo_id) {
      this.todo_id = this.ref_todo_id;
    }
    else {
      this.todo_id = this.$route.params.id;
    }

    this.loadGrantMounted();
  },
  watch: {
    ref_todo_id(newValue, oldValue) {
      this.todo_id = newValue;
    }
  },
  methods: {
    loadGrantMounted() {
      const that = this;

      this.loadGrant(() => {
        that.todo_task_id = that.task.todo_task_id;
        that.files = that.task.files;
        that.status = that.task.status;
        that.calculateSum();

        that.loadComments();
      });
    },
    saveTaskClicked() {
      this.status = this.done ? 'DONE' : 'NOT';

      axios
        .put(`/todo/${this.grant.todo_id}`, { status: this.status })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
            this.grant = res.data;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'))
        });
    },

    loadGrant(cb) {
      axios
        .get(`/todo/${this.todo_id}`)
        .then(res => {
          if (res.status === 403) {
            this.task = null;
            return;
          }

          if (res.status === 200) {
            this.grant = res.data;

            if (!this.grant.tasks || this.grant.tasks.length === 0) {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Saknas filkrav på ansökan');
              return;
            }

            this.todo_task_id = this.grant.tasks[0].todo_task_id;
            this.task = this.grant.tasks[0];

            cb();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta bidragsansökan');
        });
    },

    onSumUpdated(file_id, amount) {
      amount = (amount+'').replace(',','.');

      const file = this.files.find(item => item.file_id === file_id);
      const parsed = parseFloat(amount);
      file.amount = isNaN(parsed) ? 0 : parsed;


      axios
        .put(`/file/${file_id}`, { amount: file.amount })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
        });

      this.calculateSum();
    },


    onDescrUpdated(file_id, descr) {

      axios
        .put(`/file/${file_id}`, { descr: descr })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
        });
    },

    calculateSum() {
      this.amount_sum = 0;

      for (const f of this.files) {
        this.amount_sum += f.amount;
      }
    },

    downloadFileClicked(file_id) {
      axios
        .get(`/file/${file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta filen');
        });
    },

    deleteFileClicked(file_id) {
      axios
        .delete(`/file/${file_id}&todo_task_id=${this.task.todo_task_id}`)
        .then(res => {
          this.files = this.files.filter(file => file.file_id !== file_id);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera filen');
        });
    },

    loadComments() {
      if (!this.todo_task_id) {
        return;
      }

      axios
        .get(`/todo_task/comments/${this.todo_task_id}`)
        .then(res => {
          this.comments = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kommentarer');
        });
    },

    sendCommentClicked() {
      axios
        .post(`/todo_task/comment/${this.todo_task_id}`, {
          from: 'sub',
          comment: this.comment,
          todo_task_id: this.todo_task_id
        })
        .then(res => {
          this.comment = '';
          this.comments.push(res.data);

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka kommentar');
        });
    },

    getExtensions() {
      let exts = ['pdf','png','jpg','doc','xlsx','zip'];

      return exts.map(x => `${this.acceptLookups[x]}`).join(', ');
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },

    selectFile() {
      this.$refs.file.click();
    },

    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'todo');
      formData.append('file_type', this.task.category);
      formData.append('company_id', this.currentCompanyId);
      formData.append('todo_id', this.grant.todo_id);
      formData.append('todo_task_id', this.task.todo_task_id);

      axios
        .post(
          `/fileupload?todo=true&todo_id=${this.grant.todo_id}&todo_task_id=${this.task.todo_task_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');
          this.file_id = res.data.fileobjs[0].file_id;
          this.file.created_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
          this.file.file_id = this.file_id;

          this.$emit('fileUploaded', this.file);

          this.files.push(this.file);
          //this.wizard.goNext();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
        });
    },

    resetForm() {
      this.comment = '';
      this.todo_task_id = '';
      this.comments = [];
      this.files = [];
      this.status = '';
    },

  }
};
</script>
